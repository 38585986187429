export default {
  "container": "_container_1ymzua",
  "inner-container": "_inner-container_1ymzua",
  "register-title": "_register-title_1ymzua",
  "spacing": "_spacing_1ymzua",
  "register-form": "_register-form_1ymzua",
  "register-form-accept": "_register-form-accept_1ymzua",
  "checkbox-field": "_checkbox-field_1ymzua",
  "register-button": "_register-button_1ymzua",
  "loading": "_loading_1ymzua",
  "wrong-location": "_wrong-location_1ymzua"
};
