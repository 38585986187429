export default {
  "container": "_container_1vhi1o",
  "inner-container": "_inner-container_1vhi1o",
  "register-form": "_register-form_1vhi1o",
  "register-title": "_register-title_1vhi1o",
  "spacing": "_spacing_1vhi1o",
  "login-footer-container": "_login-footer-container_1vhi1o",
  "login-footer-container-link": "_login-footer-container-link_1vhi1o",
  "register-button": "_register-button_1vhi1o",
  "register-button-container": "_register-button-container_1vhi1o",
  "register-confirm": "_register-confirm_1vhi1o",
  "register-confirm-icon": "_register-confirm-icon_1vhi1o",
  "register-confirm-text": "_register-confirm-text_1vhi1o",
  "is_success": "_is_success_1vhi1o"
};
